
body{
  -webkit-text-size-adjust: none !important;
}
/*
html {
    -webkit-text-size-adjust: none !important;
    text-size-adjust: 100% !important;
    -moz-text-size-adjust: 100% !important;
  } */
/* .van-dialog__confirm, .van-dialog__confirm:active {
    color: #000!important;
    background-color: #25a8f5!important;
}
.van-dialog__cancel{
  color: #000!important;
    background-color: #25a8f5!important;
} */
