
.sure-btn[data-v-7ba5bd90] {
  width: 151px;
  height: 50px;
  line-height: 50px;
  background-color: #1989fa;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin-top: 16px;
  cursor: pointer;
}
.aggrementText.van-checkbox[data-v-7ba5bd90] {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 20px;
  font-size: 12px;
}
*[data-v-7ba5bd90] {
  margin: 0;
  padding: 0;
  margin: 0 auto;
}
.clearfix[data-v-7ba5bd90]:before,
.clearfix[data-v-7ba5bd90]:after {
  display: table;
  content: " ";
}
.clearfix[data-v-7ba5bd90]:after {
  clear: both;
}
.header[data-v-7ba5bd90] {
  width: 100%;
  height: 120px;
  background: #fff;
  padding: 0 358px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0 2px 5px #edebeb;
  box-shadow: 0 2px 5px #edebeb;
}
.header .container[data-v-7ba5bd90] {
  width: 1045px;
  margin: 0 auto;
}
.header img[data-v-7ba5bd90] {
  width: 217px;
  height: 73px;
  float: left;
  display: block;
  margin-top: 20px;
}
.dialog-mask[data-v-7ba5bd90] {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
}
.dialog-mask .dialog-content[data-v-7ba5bd90] {
  width: 335px;
  height: 185px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 185px;
  background: #fff;
  border-radius: 5px;
  padding: 28px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.dialog-mask .dialog-content .container[data-v-7ba5bd90] {
  padding: 0 30px;
}
.dialog-mask .dialog-content .container h4[data-v-7ba5bd90] {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #333;
  font-weight: inherit;
}
.dialog-mask .dialog-content .container .text[data-v-7ba5bd90] {
  width: 100%;
  margin-top: 20px;
}
.dialog-mask .dialog-content .container .text p[data-v-7ba5bd90] {
  font-size: 14px;
  line-height: 24px;
  color: #999;
}
.dialog-mask .dialog-content .container .text p a[data-v-7ba5bd90] {
  text-decoration: none;
  color: #ff8300;
}
.dialog-mask .dialog-content .container .btn-list[data-v-7ba5bd90] {
  width: 100%;
  margin-top: 15px;
  list-style: none;
}
.dialog-mask .dialog-content .container .btn-list li[data-v-7ba5bd90] {
  width: 50%;
  float: left;
  text-align: center;
}
.dialog-mask .dialog-content .container .btn-list .btn[data-v-7ba5bd90] {
  width: 110px;
  height: 35px;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  font-size: 16px;
  color: #fff;
  margin: 0 auto;
}
.dialog-mask .dialog-content .container .btn-list .btn[data-v-7ba5bd90]:hover {
  cursor: pointer;
}
.dialog-mask .dialog-content .container .btn-list .check[data-v-7ba5bd90] {
  background: #ff8300;
}
.dialog-mask .dialog-content .container .btn-list .get[data-v-7ba5bd90] {
  background: #ffad01;
}
.text-wrapper[data-v-7ba5bd90] {
  padding: 40px 45px;
  background: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 20px auto;
}
.text-wrapper h4[data-v-7ba5bd90] {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #333;
  margin-bottom: 24px;
}
.text-wrapper p[data-v-7ba5bd90] {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #333;
  line-height: 20px;
}
.text-wrapper p.title[data-v-7ba5bd90] {
  font-size: 18px;
  font-weight: 700;
  margin-top: 18px;
}
