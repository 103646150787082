footer[data-v-6a31aadd] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
footer[data-v-6a31aadd] {
  position: fixed;
  left: 50%;
  bottom: 0;
  width: 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 750px;
  background-color: #f5f2f0;
  text-align: center;
  font-size: 24px;
  padding: 13px 0;
}
footer > div[data-v-6a31aadd] {
  width: 50%;
}
footer > div svg[data-v-6a31aadd] {
  color: #000;
}
footer > div svg.active[data-v-6a31aadd] {
  fill: #0b8be8;
}