/*fade*/
.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.28s;
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/*fade-transform*/
.fade-transform-leave-active,
.fade-transform-enter-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
          transform: translateX(30px);
}

/*fade*/
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
}

.breadcrumb-move {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.breadcrumb-leave-active {
  position: absolute;
}

html, body {
  scroll-behavior: smooth;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: -apple-system-font, "Helvetica Neue", sans-serif;
  -webkit-overflow-scrolling: touch;
  line-height: 1.1;
}

@media (min-width: 768px) {
  html {
    font-size: 38.4Px !important;
  }
  body {
    width: 768Px;
    margin: 0 auto;
  }
}
img {
  vertical-align: middle;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  outline: none;
  text-decoration: none;
}

div:focus {
  outline: none;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

#app .van-list__finished-text {
  position: relative;
  color: #bbb;
}
#app .van-list__finished-text::before, #app .van-list__finished-text::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 3em;
  height: 2px;
  background-color: #ddd;
}
#app .van-list__finished-text::before {
  left: 24%;
}
#app .van-list__finished-text::after {
  right: 24%;
}