@font-face {
  font-family: "iconfont"; /* Project id 3661677 */
  src: url(../../static/fonts/iconfont.4aa6dc44.woff2) format('woff2'),
       url(../../static/fonts/iconfont.abf0feb8.woff) format('woff'),
       url(../../static/fonts/iconfont.bdc312dd.ttf) format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jinggao:before {
  content: "\e602";
}

.icon-002cai-1:before {
  content: "\e818";
}

.icon-news-warn:before {
  content: "\e9b4";
}

.icon-duizhangdan:before {
  content: "\e76a";
}

.icon-lacai:before {
  content: "\e64d";
}

.icon-xiaoxizhangdan:before {
  content: "\e667";
}

.icon-xiangshangjiantou:before {
  content: "\e65d";
}

.icon-xiangxiajiantou:before {
  content: "\e65e";
}

.icon-downarrow:before {
  content: "\e692";
}

.icon-xiajiantou1:before {
  content: "\e649";
}

.icon-shangjiantou:before {
  content: "\e619";
}

.icon-wodezhangdan:before {
  content: "\e73d";
}

.icon-zhangdan:before {
  content: "\e66a";
}

.icon-shengchengduizhangdan:before {
  content: "\e600";
}

.icon-shangjiantou1:before {
  content: "\e63a";
}

.icon-jinggao_o:before {
  content: "\eb80";
}

.icon-24gf-telephone:before {
  content: "\e96c";
}

.icon-youjiantou1:before {
  content: "\e60b";
}

.icon-xiangyoujiantou:before {
  content: "\e65f";
}

.icon-zuojiantou:before {
  content: "\e6e1";
}

.icon-zuojiantou1:before {
  content: "\e60e";
}

.icon-youjiantou:before {
  content: "\e60f";
}

.icon-zuojiantou2:before {
  content: "\e618";
}

.icon-kefu2:before {
  content: "\ec2e";
}

.icon-10:before {
  content: "\e61e";
}

.icon-kefu:before {
  content: "\eea9";
}

.icon-xiaoxi1:before {
  content: "\e63e";
}

.icon-xiaoxi2:before {
  content: "\e61d";
}

.icon-kefu1:before {
  content: "\e68d";
}

.icon-xiajiantou:before {
  content: "\e611";
}

.icon-xiajiantou-copy:before {
  content: "\e652";
}

.icon-arrow-down:before {
  content: "\e610";
}

.icon-xiaoxi:before {
  content: "\e62f";
}

.icon-chahao:before {
  content: "\e617";
}

.icon-tishi:before {
  content: "\e653";
}

.icon-fangzi:before {
  content: "\e8ff";
}

.icon-tishi1:before {
  content: "\e637";
}

.icon-a-xinxiyuyinxinxitishixinxi-06:before {
  content: "\e915";
}

.icon-chahao1:before {
  content: "\e68b";
}

