#login-btn[data-v-7ba5bd90] {
  padding: 0 20px;
  height: 340px;
}
.wx-btn[data-v-7ba5bd90],
.ali-btn[data-v-7ba5bd90] {
  text-align: center;
  width: 100%;
  border-color: #44b549;
  background-color: #358b98;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #44b549), to(#44b549));
  background-image: linear-gradient(to bottom, #44b549 0, #44b549 100%);
  color: #fff;
  display: inline-block;
  overflow: visible;
  padding: 0 23px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  margin-top: 30px;
}
.ali-btn[data-v-7ba5bd90] {
  margin-top: 30px;
  background: -webkit-gradient(linear, left top, right top, from(#1677ff), to(#1a57ff));
  background: linear-gradient(90deg, #1677ff, #1a57ff);
  border-color: #1677ff;
}
.loginPopup[data-v-7ba5bd90] {
  border-radius: 10px;
  width: 300px;
}
.back-btn[data-v-7ba5bd90] {
  border-radius: 25px;
}
.login-logo[data-v-7ba5bd90] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 30px;
}
.login-logo img[data-v-7ba5bd90] {
  width: 56px;
  height: 56px;
  vertical-align: middle;
}
.login-logo .title[data-v-7ba5bd90] {
  font-size: 24px;
  font-weight: 700;
  margin-left: 20px;
}
.aggrementPopup[data-v-7ba5bd90] {
  width: 350px;
  height: 500px;
}